/** @format */

.account-manage {
  margin: 20px;
}

.breadcrumb {
  font-size: 14px;
  margin-bottom: 20px;
}

.manage-container {
  display: flex;
  gap: 20px;
}

.account-menu {
  flex: 1;
}

.account-menu ul {
  list-style-type: none;
  padding: 0;
}

.account-menu li {
  margin: 10px 0;
  cursor: pointer;
  padding: 5px;
  border: 1px solid transparent;
}

.account-menu li:hover,
.account-menu li.active {
  border: 1px solid #000;
  border-radius: 4px;
}

.account-content {
  flex: 3;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.account-form {
  width: 100%;
}

.account-form h2 {
  margin-bottom: 20px;
}

.account-form form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.account-form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.account-form input[type="text"],
.account-form input[type="file"] {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.info-text {
  font-size: 12px;
  color: #777;
  margin-top: 5px;
}

.save-btn {
  width: 100px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #45a049;
}
.active {
  color: white;
}
